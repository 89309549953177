@import url("@goalteller/app-kit/styles/base.css");

:root {
  /* TODO: remove when ye-design is updated to v4 */
  --ye-shadow-input-focus: 0 0 0 1px var(--ye-color-input-focus-shadow);

  /* colors */
  --color-light-green-bg: #e6f7f3;
  --color-primary-gradient: linear-gradient(90deg, #35a7f5 0%, #068bd5 99.89%);
  --negative-header: #ff7171;
  --positive-header: #52b797;
  --color-blue-saphire: #bedae8;
  --color-sneak: #ebf8ff;
  --color-1: #fedc9c;
  --color-2: #ddaa14;
  --color-3: #e5e5e5;
  --color-4: #ecf2f4;
  --color-dark-grey: #677277;
  --sneak-peak-bg: transparent
    linear-gradient(180deg, var(--color-sneak) 0%, var(--color-canvas) 100%) 0%
    0% no-repeat padding-box;
  --light-blue-bg: var(--color-sneak) 0% 0% no-repeat padding-box;
  --blog-page-bg: transparent
    linear-gradient(180deg, var(--color-sneak) 0%, var(--color-canvas) 100%) 0%
    0% no-repeat padding-box;
  --blog-banner-image-gradient: linear-gradient(
    180deg,
    rgb(152 149 149 / 0) 0%,
    #131313 100%
  );
  --blog-slug-banner-image: linear-gradient(
    0deg,
    #fff 0%,
    #fff 31%,
    #fff0 100%
  );
  --blog-slug-page-bg: linear-gradient(
    180deg,
    #b5d4fe 0,
    #d5e7fe 200px,
    #e5f3fa 300px,
    #fff 500px,
    #fff 100%
  );
  --text-fade-white: #bfd0d9;
  --bg-color: var(--color-ink);
  --dark-blue-bg: #0076b5;
  --darker-blue-text: #02314a;
  --dark-blue-bg-2: #002133;
  --color-light-blue-divider: #b2cadd;
  --light-blue-image-linear-gradient: linear-gradient(
    180deg,
    #c6e8fb 0%,
    #99d7f8 100%
  );
  --dark-blue-image-linear-gradient: linear-gradient(
    180deg,
    #1f75a4 0%,
    #1f75a4 100%
  );
  --mid-blue-linear-gradient: linear-gradient(
    90deg,
    transparent 0%,
    #37576a 50%,
    transparent 100%
  );
  --mid-blue-radial-gradient: radial-gradient(
    58.67% 58.67% at 50% 50%,
    #02314a 36.98%,
    #000 99.62%
  );
  --dark-blue-linear-gradient: linear-gradient(
    transparent 6.26%,
    #002133 51.67%,
    transparent 97.08%
  );
  --blue-linear-gradient-simple: linear-gradient(
    114.67deg,
    #011a28 -1.05%,
    #003e5f 100%
  );
  --dark-blue-45-gradient: linear-gradient(294.67deg, #02314a 0%, #000 101.05%);
  --dark-grey-border-gradient: linear-gradient(
    90deg,
    rgb(78 91 98 / 0.3) 0%,
    rgb(137 209 250 / 0.4) 50%,
    rgb(78 91 98 / 0.3) 100%
  );
  --linear-gradient-blue-d1: linear-gradient(
    #002133 6.26%,
    #000 51.67%,
    #002133 97.08%
  );
  --mid-blue-gradient-l1: linear-gradient(
    -45deg,
    #001b29 0%,
    #0c4362 50%,
    #001b29 100%
  );
  --blue-glow-gradient: radial-gradient(
    circle,
    #009dff6e 0,
    #00c8ff00 70%,
    #0000 100%
  );
  --warning-tape-bg: transparent
    linear-gradient(270deg, var(--color-canvas) 0%, #fef4f3 100%) 0% 0%
    no-repeat padding-box;

  /* gradients */
  --min-card-height: 280px;
  --container-small: 100px;
  --blog-search-max-width: 268px;
  --max-card-width: 320px;
  --title-width: 730px;
  --blog-search-tablet-width: 75%;
  --blog-page-loader-min-height: 80vh;
  --root-min-height: 100vh;
  --blog-search-border-radius: calc(2 * var(--ye-radius-xlarge));
  --blog-search-input-padding: calc(2 * var(--ye-spacing-rem-xlarge));
  --screen-width: 1300px;
  --ye-spacing-rem-giant: calc(24 * var(--ye-rem-unit));
}

.toast-container {
  top: calc(3 * var(--ye-spacing-rem-xlarge));
}

.bpw-floating-button {
  @mixin focusAndHover {
    background-color: var(--color-primary);
  }

  background-color: var(--color-primary);
}

[data-theme="light"] {
  --background-color: var(--color-canvas);
  --card-white-color: #f5f5f5;
  --bg-color: var(--color-canvas);
  --primary-text-color: var(--color-ink);
  --text-fade-white: var(--color-ink);
  --dark-blue-45-gradient: #99d7f8;
  --invert-color: invert(1);
  --border-color: var(--color-ink);
  --bg-play-btn: var(--color-primary);
  --color-numbers-card: var(--color-blue-c2-l5);
  --blue-image-bg: var(--dark-blue-image-linear-gradient);
  --color-btn-signup: var(--color-primary);
  --text-blue-gradient: var(--color-primary);
  --darker-blue: var(--darker-blue-text);
  --color-ribbon: #2e5f9b;
  --color-ribbon-text: var(--color-canvas);
  --color-review-card-headline: #2c3e50;
  --color-designation: #4e5b62;
  --color-email-inut: var(--color-canvas);
  --color-review-card-paragraph: var(--color-review-card-headline);
  --color-review-card-user: var(--color-primary);
}

[data-theme="dark"] {
  --card-white-color: var(--dark-blue-45-linear-gradient);
  --background-color: var(--color-ink);
  --bg-color: var(--color-ink);
  --border-color: none;
  --primary-text-color: var(--color-canvas);
  --text-fade-white: #bfd0d9;
  --blue-image-bg: var(--light-blue-image-linear-gradient);
  --color-numbers-card: var(--dark-blue-45-linear-gradient);
  --color-btn-signup: var(--color-canvas);
  --color-review-card-headline: #f5f5f5;
  --color-review-card-paragraph: #9fb9c8;
  --color-review-card-user: #b6e5ff;
  --color-ribbon-text: #044d75;
  --color-designation: var(--color-review-card-paragraph);
  --color-ribbon: #b6e5ff;
  --color-email-inut: transparent;
  --darker-blue: transparent;
  --bg-play-btn: transparent;
  --invert-color: invert(0);
  --dark-blue-45-gradient: linear-gradient(294.67deg, #02314a 0%, #000 101.05%);
}
