/* jscpd:ignore-start */

.root {
  width: calc(100% - 2 * var(--ye-spacing-rem-normal)) !important;
  max-width: var(--ye-breakpoint-lg);
  height: 0;
  margin: 0 auto;

  background: transparent;
  border-radius: var(--ye-font-size-xhuge);

  & .button {
    @mixin focusAndHover {
      color: var(--color-primary);
      background-color: transparent;
    }

    color: var(--color-canvas);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  @media (--media-min-xxxl) {
    max-width: var(--ye-breakpoint-xl);
  }
}

.drawer-container {
  padding: var(--ye-spacing-rem-normal);
  padding-right: 0;
  color: var(--color-canvas);
  background: var(--color-canvas);
}

.dark-drawer-container {
  padding: var(--ye-spacing-rem-normal);
  padding-right: 0;
  color: var(--color-ink);
  background: var(--dark-blue-gradient);
}

.drawer-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container {
  position: relative;
  top: var(--ye-spacing-rem-normal);

  align-items: center;

  width: 100%;
  margin: 0 auto;
  padding-block: var(--ye-spacing-rem-xsmall);
  padding-inline: var(--ye-spacing-rem-small);

  background: var(--color-canvas);
  border-radius: var(--ye-font-size-xhuge);
  box-shadow: var(--ye-shadow-floating-low);
}

.content-menu {
  width: calc(100vw - 2 * var(--ye-spacing-rem-large));
  margin: 0 auto;

  border-radius: var(--ye-spacing-rem-normal);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.link {
  @mixin focusAndHover {
    color: var(--color-primary);
    text-decoration: none;
  }

  padding: var(--ye-spacing-rem-tiny) var(--ye-spacing-rem-huge);

  font-size: var(--ye-font-size-normal);
  font-weight: var(--ye-font-weight-normal);
  color: var(--color-ink-l2);

  border-radius: var(--ye-radius-small);

  transition-property: background-color;
}

.menu {
  position: relative;
  display: flex;

  & a {
    @mixin focusAndHover {
      color: var(--color-primary);
      text-decoration: none;
    }
  }

  @media (--media-max-md) {
    flex-direction: column;
    gap: var(--ye-spacing-rem-normal);
    align-items: flex-end;

    margin-left: 0;
    padding-left: var(--ye-spacing-em-normal);
  }
}

.close-icon {
  color: var(--color-ink);
}

.dark-close-icon {
  color: var(--color-canvas);
}

.menu-wrapper {
  display: flex;
  align-items: center;

  @media (--media-max-md) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.arrow-icon {
  transform: translateY(calc(0px - var(--ye-input-border-width)));
  width: var(--ye-font-size-large);
  height: var(--ye-font-size-large);
  color: var(--color-canvas);
}

.logo {
  display: flex;

  @media (--media-max-xl) {
    margin-left: 0;
  }
}

.logo-image {
  width: auto;
  height: 40px;

  @media (--media-max-md) {
    height: var(--ye-spacing-rem-huge);
  }
}

.is-expanded {
  & .container {
    background: transparent;
    box-shadow: none;
  }

  &.theme-hero-normal,
  &.theme-hero-light-blue {
    & .link {
      color: var(--color-ink-l2);

      &.active-tab {
        color: var(--color-primary);
      }
    }
  }

  &.theme-hero-violet,
  &.theme-hero-denim {
    & .link {
      color: var(--color-canvas);

      & :global(.active) {
        color: var(--color-primary);
      }

      @media (--media-max-md) {
        color: var(--color-ink-l2);
      }
    }
  }

  &.theme-hero-dark-brown,
  &.theme-hero-light-orange,
  &.theme-hero-light-green,
  &.theme-hero-light-pink,
  &.theme-hero-light-neon,
  &.theme-hero-light-mud,
  &.theme-hero-green-neon,
  &.theme-hero-baby-pink {
    & .link {
      color: var(--color-canvas);

      & :global(.active) {
        color: var(--color-primary);
      }
    }

    & .button {
      color: var(--color-primary);
      background-color: var(--color-canvas);
      border: var(--color-canvas);
    }

    @media (--media-max-md) {
      & .link {
        color: var(--color-ink-l2);
      }
    }
  }
}

.active-tab {
  /* no styles */
}

.right-wrapper {
  display: flex;

  @media (--media-max-md) {
    flex-direction: column;
  }
}

.container-dark {
  position: relative;
  top: var(--ye-spacing-rem-normal);

  align-items: center;

  width: var(--full-width);
  margin: 0 auto;
  padding-block: var(--ye-spacing-rem-normal);
  padding-inline: var(--ye-spacing-rem-small);

  background: var(--color-ink);
  border-radius: var(--ye-font-size-xhuge);
  box-shadow: var(--ye-shadow-floating-low);

  &::after {
    content: "";

    position: absolute;
    top: var(--full-width);
    left: 50%;
    translate: -50% -50%;

    width: var(--full-width);
    height: 0.5px;

    background: var(--mid-blue-linear-gradient);
    border-radius: var(--ye-font-size-xhuge);
  }

  & button {
    color: var(--color-canvas);
  }
}

.content-menu-dark {
  background: var(--dark-blue-45-gradient);
  border-radius: var(--ye-spacing-rem-normal);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sign-in-btn {
  color: var(--color-canvas);

  @media (--media-max-md) {
    margin: var(--ye-spacing-rem-large);
    color: var(--color-ink);
    background-color: var(--color-canvas);
  }
}

.link-dark-theme {
  @mixin focusAndHover {
    color: var(--color-primary);
    text-decoration: none;
  }

  padding: var(--ye-spacing-rem-tiny) var(--ye-spacing-rem-huge);

  font-size: var(--ye-font-size-normal);
  font-weight: var(--ye-font-weight-normal);
  color: var(--color-canvas);

  border-radius: var(--ye-radius-small);

  transition-property: background-color;

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xlarge);
  }
}

/* jscpd:ignore-end */
