.form {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: var(--container-button-small);
  height: 36px;
  margin: auto;
  margin-top: var(--ye-spacing-rem-huge);

  font-size: var(--ye-font-size-xsmall);
  font-weight: 600;

  border-radius: var(--pill-radius);
}

.filled {
  @mixin focusAndHover {
    color: var(--color-canvas);
  }

  color: var(--color-canvas);
  background: var(--color-primary);
}

.primary-outline {
  color: var(--color-primary);
  background: var(--color-canvas);
  border: 2px solid var(--color-primary);
}

.gradient-button {
  color: var(--color-canvas);
  background: linear-gradient(
    112deg,
    var(--color-primary-l1),
    var(--color-primary) 100%
  );
}

.pricing-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--ye-spacing-rem-small) var(--ye-spacing-rem-large);

  font-size: var(--ye-font-size-small);
}
